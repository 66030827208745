import React, { useEffect, useState } from 'react'
import './Row.css'
import Airtable from 'airtable'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from '../../img/1.jpg'
import img2 from '../../img/2.jpg'
import img3 from '../../img/3.jpg'
import img4 from '../../img/4.jpg'
import img5 from '../../img/5.jpg'
import img6 from '../../img/6.jpg'
import img7 from '../../img/7.jpg'
import img8 from '../../img/8.jpg'
import img9 from '../../img/9.jpg'
import img10 from '../../img/10.jpg'
import img11 from '../../img/11.jpg'
import img12 from '../../img/12.jpg'
import img13 from '../../img/13.jpg'
import img14 from '../../img/14.jpg'
import img15 from '../../img/15.jpg'
import img16 from '../../img/16.jpg'
import imglh1 from '../../img/lh1.jpg'
import imglh2 from '../../img/lh2.jpg'
import imglh3 from '../../img/lh3.jpg'
import imglh4 from '../../img/lh4.jpg'
import imglh5 from '../../img/lh5.jpg'
import imglh6 from '../../img/lh6.jpg'
import imglh7 from '../../img/lh7.jpg'
import imglh8 from '../../img/lh8.jpg'

const base = new Airtable({ apiKey: 'keyiy8qts9Jrb6RTd' }).base('app13EMj5Cu4BWQBD');
function Row1() {

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );

    const settings = {
        infinite: true,
        dots: false,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        variableWidth: true,
        adaptiveHeight: true,
        nextArrow: <ChevronRightIcon />,
        prevArrow: <ChevronLeftIcon />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]

    };

    const [film, setFilms] = useState([]);
    useEffect(() => {
        base('tbls5SAopmGvFrx0X')
            .select({
                view: 'Grid view'
            }).eachPage((records, fecthNextPage) => {
                //console.log(records);
                setFilms(records);
                fecthNextPage();
            });
    }, []);
    return (
        <div className="row1">
            {/* title */}

            {/* posters */}

            <div className="row__posters d-xl-none">


                <img className="row__posterMob" src={img1} alt="no image" />
                <img className="row__posterMob" src={img2} alt="no image" />
                <img className="row__posterMob" src={img3} alt="no image" />
                <img className="row__posterMob" src={img4} alt="no image" />
                <img className="row__posterMob" src={img5} alt="no image" />
                <img className="row__posterMob" src={img6} alt="no image" />
                <img className="row__posterMob" src={img7} alt="no image" />
                <img className="row__posterMob" src={img8} alt="no image" />
                <img className="row__posterMob" src={img9} alt="no image" />
                <img className="row__posterMob" src={img10} alt="no image" />
                <img className="row__posterMob" src={img11} alt="no image" />
                <img className="row__posterMob" src={img12} alt="no image" />

                <img className="row__posterMob" src={img13} alt="no image" />
                <img className="row__posterMob" src={img14} alt="no image" />
                <img className="row__posterMob" src={img15} alt="no image" />
                <img className="row__posterMob" src={img16} alt="no image" />


                <img className="row__posterMob" src={imglh1} alt="no image" />
                <img className="row__posterMob" src={imglh2} alt="no image" />
                <img className="row__posterMob" src={imglh3} alt="no image" />

                <img className="row__posterMob" src={imglh4} alt="no image" />
                <img className="row__posterMob" src={imglh5} alt="no image" />
                <img className="row__posterMob" src={imglh6} alt="no image" />
                <img className="row__posterMob" src={imglh7} alt="no image" />
                <img className="row__posterMob" src={imglh8} alt="no image" />

            </div>

            <Slider {...settings} className="d-none d-xl-block">

                <img className="row__poster"
                    src={img1} alt="no image" />
                <img className="row__poster"
                    src={img2} alt="no image" />
                <img className="row__poster"
                    src={img3} alt="no image" />
                <img className="row__poster"
                    src={img4} alt="no image" />
                <img className="row__poster"
                    src={img5} alt="no image" />
                <img className="row__poster"
                    src={img6} alt="no image" />
                <img className="row__poster"
                    src={img7} alt="no image" />
                <img className="row__poster"
                    src={img8} alt="no image" />
                <img className="row__poster"
                    src={img9} alt="no image" />
                <img className="row__poster"
                    src={img10} alt="no image" />
                <img className="row__poster"
                    src={img11} alt="no image" />
                <img className="row__poster"
                    src={img12} alt="no image" />

                <img className="row__poster"
                    src={img13} alt="no image" />
                <img className="row__poster"
                    src={img14} alt="no image" />
                <img className="row__poster"
                    src={img15} alt="no image" />
                <img className="row__poster"
                    src={img16} alt="no image" />


                <img className="row__poster"
                    src={imglh1} alt="no image" />
                <img className="row__poster"
                    src={imglh2} alt="no image" />
                <img className="row__poster"
                    src={imglh3} alt="no image" />

                <img className="row__poster"
                    src={imglh4} alt="no image" />
                <img className="row__poster"
                    src={imglh5} alt="no image" />
                <img className="row__poster"
                    src={imglh6} alt="no image" />
                <img className="row__poster"
                    src={imglh7} alt="no image" />
                <img className="row__poster"
                    src={imglh8} alt="no image" />


            </Slider>


        </div>


    )
}

export default Row1
