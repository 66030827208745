import React, { useState, useEffect } from "react";

import "./Navs2.css";
import logo from "./mv.png";
import { useHistory } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";

import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
function Nav({ active }) {
  const [show, handleShow] = useState(false);
  const [isActive, setisActive] = useState("");

  let history = useHistory();

  const [open, setOpen] = useState(true);

  const body = document.querySelector("body");

  function bodystyle(e) {
    document.body.classList.add("disabled");
  }

  function removestyle(e) {
    document.body.classList.remove("disabled");
  }

  const transitionNavBar = () => {
    if (window.scrollY > 1) {
      handleShow(true);
    } else if (window.scrollY > -10) {
      handleShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", transitionNavBar);
    return () => window.removeEventListener("scroll", transitionNavBar);
  }, []);

  return (
    <nav className={` navbars ${open ? "" : " show"} ${show && "sticky"}   `}>
      <div className="content">
        <div className=" plane__title">
          "ASSURER LA PÉRÉNITÉ DE VOTRE ENTREPRISE EN TOUTE SÉRÉNITÉ"
        </div>

        <img className="nav__logo" src={logo} alt="logo" />
        <div className="invisible_button d-none"></div>
      </div>
    </nav>
  );
}

export default Nav;
